<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Empirical Formula - Data Sheet</h2>

      <p class="mb-5">
        Please use the data collected from the experiment to fill in the data tables below. Note
        that some of the information is duplicated in the second table to help guide you through the
        calculations.
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <td style="width: 50%">
              <stemble-latex content="$\textbf{Variable}$" />
            </td>
            <td style="width: 50%">
              <stemble-latex content="$\textbf{Value}$" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Mass of crucible, cover, and Mg ribbon (g):</td>
            <td class="pt-4">
              <v-text-field
                v-model="inputs.crucibleCoverRibbon"
                dense
                outlined
                :rules="massRules"
                validate-on-blur
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Mass of crucible and cover (g):</td>
            <td class="pt-4">
              <v-text-field
                v-model="inputs.crucibleCover"
                dense
                outlined
                :rules="massRules"
                validate-on-blur
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Mass of the Mg ribbon (g):</td>
            <td class="pt-4">
              <v-text-field
                v-model="inputs.MgRibbon"
                dense
                outlined
                :rules="sigFigRules"
                validate-on-blur
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Mass of crucible, cover, and product (g):</td>
            <td class="pt-4">
              <v-text-field
                v-model="inputs.crucibleCoverProduct"
                dense
                outlined
                :rules="massRules"
                validate-on-blur
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Mass of oxygen added (g):</td>
            <td class="pt-4">
              <v-text-field
                v-model="inputs.oxygenAdded"
                dense
                outlined
                :rules="sigFigRules"
                validate-on-blur
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\textbf{Variable}$" />
            </td>
            <td>
              <stemble-latex content="$\textbf{Value}$" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Mass of Mg ribbon (g):</td>
            <td class="pt-4">
              <v-text-field
                v-model="inputs.MgRibbon"
                dense
                outlined
                :rules="sigFigRules"
                validate-on-blur
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Molar mass of Mg (g/mol):</td>
            <td>
              <calculation-input
                v-model="inputs.molarMassMg"
                dense
                :show-helpful-hint="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Moles of Mg ribbon (mol):</td>
            <td>
              <calculation-input
                v-model="inputs.molMg"
                dense
                :show-helpful-hint="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Mass of oxygen added (g):</td>
            <td class="pt-4">
              <v-text-field
                v-model="inputs.oxygenAdded"
                dense
                outlined
                :rules="sigFigRules"
                validate-on-blur
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Molar mass of oxygen (g/mol):</td>
            <td>
              <calculation-input
                v-model="inputs.molarMassO"
                dense
                :show-helpful-hint="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Moles of oxygen added (mol):</td>
            <td>
              <calculation-input
                v-model="inputs.molO"
                dense
                :show-helpful-hint="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>Empirical Formula:</td>
            <td>
              <chemical-notation-input
                v-model="inputs.empiricalFormula"
                dense
                :show-note="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import ChemicalSymbolInput from '@/tasks/components/inputs/ChemicalSymbolInput.vue';

export default {
  name: 'OleMissLab7ReportSheet1',
  components: {ChemicalSymbolInput, ChemicalNotationInput, CalculationInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        crucibleCoverRibbon: null,
        crucibleCover: null,
        MgRibbon: null,
        crucibleCoverProduct: null,
        oxygenAdded: null,
        molarMassMg: null,
        molMg: null,
        molarMassO: null,
        molO: null,
        empiricalFormula: null,
      },
      massRules: [
        (v) => !!v || 'Mass is required',
        (v) =>
          /^[-+]?\d+(\.\d*)?([eE][-+]?\d+(\.\d*)?)?$/.test(v) ||
          'Only numerical characters allowed',
        (v) =>
          (v && v.toString().split('.').length < 2) ||
          (v && v.toString().split('.')[1].length === 3) ||
          "Number of decimal places doesn't match balance output",
      ],
      sigFigRules: [
        (v) => !!v || 'This value is required',
        (v) =>
          /^[-+]?\d+(\.\d*)?([eE][-+]?\d+(\.\d*)?)?$/.test(v) ||
          'Only numerical characters allowed',
        (v) =>
          (v && v.toString().split('.').length < 2) ||
          (v && v.toString().split('.')[1].length === 3) ||
          'Double check your significant figures',
      ],
    };
  },
};
</script>
<style scoped></style>
